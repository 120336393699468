body {
  margin: 0;
  background-color: #e6f9ff;
}

#canvas-container {
  background-color: #e6f9ff;
}

#canvas-container.background-black {
  background-color: black;
}

/*   */ 

.initialization-container { 
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.initialization-container p {
  font-size: 3em;
  text-align: center;
}

/* */

.game-loader-container { 
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.game-loader-container p {
  font-size: 3em;
  text-align: center;
}

/* */ 

.main-menu-container { 
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

.main-menu-container p {
  padding: 0.1em;
  margin: 0.5em;
  font-size: 3em;
  text-align: center;
}

.main-menu-container button {
  padding: 0.1em;
  margin: 0.5em;
  height: 3em;
  font-size: 3em;
}

/* */ 

.gather-info-container { 
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

.gather-info-container p {
  padding: 0.1em;
  margin: 0.5em;
  font-size: 3em;
  text-align: center;
}

.gather-info-container input {
  padding: 0.1em;
  margin: 0.5em;
  height: 3em;
  font-size: 3em;
  text-align: center;
}

.gather-info-container button {
  padding: 0.1em;
  margin: 0.5em;
  height: 3em;
  font-size: 3em;
}

/* */

#canvas-container #fps-overlay {
  position: absolute; 
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.5);
  font-size: 3rem;
}

#canvas-container #overview-toggle-overlay.overview-toggle-on {
  position: absolute;
  right: 0;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.75);
  font-size: 3rem;
}

#canvas-container #overview-toggle-overlay.overview-toggle-off {
  position: absolute;
  right: 0;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.5);
  font-size: 3rem;
}

#canvas-container #debug-overlay {
  position: absolute;
}

/* */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
