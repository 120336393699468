body {
  background-color: #e6f9ff;
  margin: 0;
}

#canvas-container {
  background-color: #e6f9ff;
}

#canvas-container.background-black {
  background-color: #000;
}

.initialization-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.initialization-container p {
  text-align: center;
  font-size: 3em;
}

.game-loader-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.game-loader-container p {
  text-align: center;
  font-size: 3em;
}

.main-menu-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.main-menu-container p {
  text-align: center;
  margin: .5em;
  padding: .1em;
  font-size: 3em;
}

.main-menu-container button {
  height: 3em;
  margin: .5em;
  padding: .1em;
  font-size: 3em;
}

.gather-info-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.gather-info-container p {
  text-align: center;
  margin: .5em;
  padding: .1em;
  font-size: 3em;
}

.gather-info-container input {
  height: 3em;
  text-align: center;
  margin: .5em;
  padding: .1em;
  font-size: 3em;
}

.gather-info-container button {
  height: 3em;
  margin: .5em;
  padding: .1em;
  font-size: 3em;
}

#canvas-container #fps-overlay {
  background-color: #ffffff80;
  border-radius: 5px;
  margin: .5em;
  padding: .5em;
  font-size: 3rem;
  position: absolute;
}

#canvas-container #overview-toggle-overlay.overview-toggle-on {
  background-color: #ffffffbf;
  border-radius: 5px;
  margin: .5em;
  padding: .5em;
  font-size: 3rem;
  position: absolute;
  right: 0;
}

#canvas-container #overview-toggle-overlay.overview-toggle-off {
  background-color: #ffffff80;
  border-radius: 5px;
  margin: .5em;
  padding: .5em;
  font-size: 3rem;
  position: absolute;
  right: 0;
}

#canvas-container #debug-overlay {
  position: absolute;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

/*# sourceMappingURL=index.863d760a.css.map */
